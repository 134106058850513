.cm-chessboard {
  --marker-color: #007354;
  --marker-color-red: #aa0000;
  /* --marker-opacity: 0.35; */
}

.cm-chessboard .board.input-enabled .square {
  cursor: pointer;
}

.cm-chessboard .markers .marker.markerFrame,
.cm-chessboard .markers .marker.marker-frame {
  stroke: var(--marker-color);
  stroke-width: 1.8px;
  opacity: 0.5;
}

.cm-chessboard .markers .marker.markerFrameRed,
.cm-chessboard .markers .marker.marker-frame-red {
  stroke: var(--marker-color-red);
  stroke-width: 1.8px;
  opacity: 0.3;
}

.cm-chessboard .markers .marker.markerSquareFull,
.cm-chessboard .markers .marker.marker-square-full {
  fill: var(--marker-color);
  opacity: 1;
}

.cm-chessboard .markers .marker.markerSquareFullRed,
.cm-chessboard .markers .marker.marker-square-full-red {
  fill: var(--color-red);
  opacity: 1;
}


/* var(--color-red); */

.cm-chessboard .markers .marker.markerSquare,
.cm-chessboard .markers .marker.marker-square {
  fill: var(--marker-color);
  opacity: 0.5;
}

.cm-chessboard .markers .marker.markerDot,
.cm-chessboard .markers .marker.marker-dot {
  fill: var(--marker-color);
  opacity: 0.3;
}

.cm-chessboard .markers .marker.markerCircle,
.cm-chessboard .markers .marker.marker-circle {
  stroke: var(--marker-color);
  stroke-width: 3px;
  opacity: 0.4;
}

.cm-chessboard .markers .marker.markerCircleRed,
.cm-chessboard .markers .marker.marker-circle-red {
  stroke: var(--marker-color-red);
  stroke-width: 3px;
  opacity: 0.4;
}

.cm-chessboard .pieces,
.cm-chessboard .markers {
  pointer-events: none;
}

.cm-chessboard.default .board .square.white {
  fill: #ecdab9;
}

.cm-chessboard.default .board .square.black {
  fill: #c5a076;
}

.cm-chessboard.default.border-type-thin .board .border {
  stroke: #c5a076;
  stroke-width: 0.7%;
  fill: transparent;
}

.cm-chessboard.default.border-type-frame .board .border {
  fill: #ecdab9;
  stroke: none;
}

.cm-chessboard.default.border-type-frame .board .border-inner {
  fill: transparent;
  stroke: #c5a076;
  stroke-width: 0.7%;
}

.cm-chessboard.default .coordinates {
  pointer-events: none;
  user-select: none;
}
.cm-chessboard.default .coordinates .coordinate {
  fill: #c5a076;
  font-size: 7px;
  cursor: default;
}
.cm-chessboard.default .coordinates .coordinate.black {
  fill: #ecdab9;
}
.cm-chessboard.default .coordinates .coordinate.white {
  fill: #c5a076;
}

.cm-chessboard.green .board .square.white {
  fill: #e0ddcc;
}

.cm-chessboard.green .board .square.black {
  fill: #4c946a;
}

.cm-chessboard.green.border-type-thin .board .border {
  stroke: #4c946a;
  stroke-width: 0.7%;
  fill: transparent;
}

.cm-chessboard.green.border-type-frame .board .border {
  fill: #e0ddcc;
  stroke: none;
}

.cm-chessboard.green.border-type-frame .board .border-inner {
  fill: transparent;
  stroke: #4c946a;
  stroke-width: 0.7%;
}

.cm-chessboard.green .coordinates {
  pointer-events: none;
  user-select: none;
}
.cm-chessboard.green .coordinates .coordinate {
  fill: #4c946a;
  font-size: 7px;
  cursor: default;
}
.cm-chessboard.green .coordinates .coordinate.black {
  fill: #e0ddcc;
}
.cm-chessboard.green .coordinates .coordinate.white {
  fill: #4c946a;
}

.cm-chessboard.blue .board .square.white {
  fill: #ebf0f5;
}

.cm-chessboard.blue .board .square.black {
  fill: #678fb1;
}

.cm-chessboard.blue.border-type-thin .board .border {
  stroke: #678fb1;
  stroke-width: 0.7%;
  fill: transparent;
}

.cm-chessboard.blue.border-type-frame .board .border {
  fill: #ebf0f5;
  stroke: none;
}

.cm-chessboard.blue.border-type-frame .board .border-inner {
  fill: transparent;
  stroke: #678fb1;
  stroke-width: 0.7%;
}

.cm-chessboard.blue .coordinates {
  pointer-events: none;
  user-select: none;
}
.cm-chessboard.blue .coordinates .coordinate {
  fill: #678fb1;
  font-size: 7px;
  cursor: default;
}
.cm-chessboard.blue .coordinates .coordinate.black {
  fill: #ebf0f5;
}
.cm-chessboard.blue .coordinates .coordinate.white {
  fill: #678fb1;
}

.cm-chessboard.chess-club .board .square.white {
  fill: #e6d3b1;
}

.cm-chessboard.chess-club .board .square.black {
  fill: #af6b3f;
}

.cm-chessboard.chess-club.border-type-thin .board .border {
  stroke: #692e2b;
  stroke-width: 0.7%;
  fill: transparent;
}

.cm-chessboard.chess-club.border-type-frame .board .border {
  fill: #692e2b;
  stroke: none;
}

.cm-chessboard.chess-club.border-type-frame .board .border-inner {
  fill: transparent;
  stroke: #692e2b;
  stroke-width: 0.7%;
}

.cm-chessboard.chess-club .coordinates {
  pointer-events: none;
  user-select: none;
}
.cm-chessboard.chess-club .coordinates .coordinate {
  fill: #e6d3b1;
  font-size: 7px;
  cursor: default;
}
.cm-chessboard.chess-club .coordinates .coordinate.black {
  fill: #e6d3b1;
}
.cm-chessboard.chess-club .coordinates .coordinate.white {
  fill: #af6b3f;
}

.cm-chessboard.chessboard-js .board .square.white {
  fill: #f0d9b5;
}

.cm-chessboard.chessboard-js .board .square.black {
  fill: #b58863;
}

.cm-chessboard.chessboard-js.border-type-thin .board .border {
  stroke: #404040;
  stroke-width: 0.7%;
  fill: transparent;
}

.cm-chessboard.chessboard-js.border-type-frame .board .border {
  fill: #f0d9b5;
  stroke: none;
}

.cm-chessboard.chessboard-js.border-type-frame .board .border-inner {
  fill: transparent;
  stroke: #404040;
  stroke-width: 0.7%;
}

.cm-chessboard.chessboard-js .coordinates {
  pointer-events: none;
  user-select: none;
}
.cm-chessboard.chessboard-js .coordinates .coordinate {
  fill: #404040;
  font-size: 7px;
  cursor: default;
}
.cm-chessboard.chessboard-js .coordinates .coordinate.black {
  fill: #f0d9b5;
}
.cm-chessboard.chessboard-js .coordinates .coordinate.white {
  fill: #b58863;
}

.cm-chessboard.black-and-white .board .square.white {
  fill: #ffffff;
}

.cm-chessboard.black-and-white .board .square.black {
  fill: #9c9c9c;
}

.cm-chessboard.black-and-white.border-type-thin .board .border {
  stroke: #9c9c9c;
  stroke-width: 0.7%;
  fill: transparent;
}

.cm-chessboard.black-and-white.border-type-frame .board .border {
  fill: #ffffff;
  stroke: none;
}

.cm-chessboard.black-and-white.border-type-frame .board .border-inner {
  fill: transparent;
  stroke: #9c9c9c;
  stroke-width: 0.7%;
}

.cm-chessboard.black-and-white .coordinates {
  pointer-events: none;
  user-select: none;
}
.cm-chessboard.black-and-white .coordinates .coordinate {
  fill: #9c9c9c;
  font-size: 7px;
  cursor: default;
}
.cm-chessboard.black-and-white .coordinates .coordinate.black {
  fill: #ffffff;
}
.cm-chessboard.black-and-white .coordinates .coordinate.white {
  fill: #9c9c9c;
}

/*# sourceMappingURL=cm-chessboard.css.map */
